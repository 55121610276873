.user-history-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    max-height: 100%;

    .accum-data-container{
        display: flex;
        justify-content: space-between;

        .card{
            width: 48%;
            height: 5rem;
            background: #FFFFFF;
            border-radius: 15px;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .label{
                font-size: 0.9em;
                font-weight: 700;
                letter-spacing: 0.02em;
            }

            .value{
                font-weight: 700;
                font-size: 1.2em;
                letter-spacing: 0.02em;
                margin-top: 0.3rem;
            }

            .value.travelled{
                color: #64B28E;
            }

            .value.visits{
                color: #FF6881
                ;
            }
        }
    }

    .detailed-history{
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 1.5rem;
        min-height: 100%;
        max-height: 100%;
        .header{
            font-weight: 700;
            font-size: 0.9em;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-container{
                width: 7rem;

                .download-link{
                    text-decoration: none;
                }
            }
        }

        .history-list{
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;
            min-height: 100%;
            max-height: 100%;
            overflow: auto;
            .list{
                display: flex;
                justify-content: space-between;
                background: #FFFFFF;
                border-radius: 10px;
                padding: 1rem 1rem;
                margin-bottom: 0.5rem;
                align-items: center;
                position: relative;
                overflow: hidden;
                min-height: 2rem;

                .date{
                    font-weight: 700;
                    font-size: 0.8em;
                }

                .kms{
                    background: #169BD3;
                    border-radius: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.3rem 1rem;
                    font-weight: 700;
                    font-size: 0.8em;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                }
            }

            .list.pending::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0.5rem;
                background-color: #169BD3;
            }

            .list.rejected::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0.5rem;
                background-color: #FF6881;
            }

            .list.approved::before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                width: 0.5rem;
                background-color: #64B28E;
            }
        }
    }
    .download-csv-modal{
        display: flex;
        justify-content: center;
        align-items: center;
        position:absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        .back-modal{
            background: rgba(0, 0, 0, 0.37);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
            .modal-box{
                min-width: 20rem;
                padding: 0rem .5rem;
                max-width: 20rem;
                height: 7rem;
                background: #F9F8F8;
                border-radius: 8px;
                z-index: 1000;
                display: flex;
                flex-direction: column;
                margin-top: 20rem !important;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                justify-content: space-between;
                .header{
                    display: flex;
                    padding: 10px;
                    .title{
                        color: #877e7e;
                    }
                    .close{
                        cursor: pointer;
                        font-size: 12px;
                        color: rgb(119, 109, 109);
                    }
                    .close:hover{
                        color: #FF6881;
                        font-size: 14px;
                    }
                }
                .modal-body{

                }
                .modal-footer{
                    display: flex;
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    .modal-action{
                        width: 7rem;
                    }
                }
            }
        }
    }
}
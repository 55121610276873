.notification-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    max-height: 100%;
    padding: 1rem 1rem;

    .header{
        font-weight: 700;
        font-size: 0.9em;
    }

    .notifications-list{
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        min-height: 100%;
        max-height: 100%;
        overflow: auto;
        flex: 1;
        .list{
            display: flex;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 1rem 1rem;
            margin-bottom: 1rem;
            align-items: center;
            position: relative;
            overflow: hidden;
            min-height: 2rem;
            max-height: 2rem;

            .notification-text{
                font-weight: 700;
                letter-spacing: 0.02em;
                font-size: 0.9em;
                margin-left: 0.3rem;
            }
        }

        .list.visitRejected::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0.5rem;
            background-color: #FF6881;
        }

        .list.visitsApproved::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0.5rem;
            background-color: #64B28E;
        }

        .list.visitLocationUpdated::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0.5rem;
            background-color: #169BD3;
        }

    }
}
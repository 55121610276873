.InstallModal-container{
    display: flex;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    align-items: center;
    justify-content: center;

    .backdrop{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999991;
        background-color: #00000082;
    }

    .install-modal{
        z-index: 999992;
        width: 90%;
        height: 10rem;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-radius: 5px;
        flex-direction: column;

        .install-text{
            font-size: 1.2em;
            font-weight: 500;
        }

        .btn-container{
            width: 65%;
            max-width: 15rem;
        }
    }
}
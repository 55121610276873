.errorfallback-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 90vh;

    svg{
        width: 7rem;
        height: 7rem;
    }

    .errorfallback-message{
        font-size: 1.3em;
        margin-top: 1rem;
        font-weight: 700;
        color: #464646;
    }
}
.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: 500;
    background: linear-gradient(167.01deg, #84D8E1 -6.57%, #5ADAB6 81.03%);
    color: #ffffff;
}
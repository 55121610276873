.profile-container{
    display: flex;
    flex-direction: column;
    flex: 1;

    .tab-container{
        padding: 1rem 0 0 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 100%;
        max-height: 100%;

        .tabs{
            display: flex;

            .tab{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 2rem;
                border-bottom: 2px solid #e4e2e5;
                font-weight: 700;
                font-size: 0.8em;
                color: #5A5A5A;
            }

            .tab.active{
                color: #FF6881;
                border-color: #FF6881;
            }
        }

        .tab-content{
            padding: 1rem 1rem;
            flex: 1;
            display: flex;
            flex-direction: column;
            min-height: 100%;
            max-height: 100%;
            padding-bottom: 0;
        }
    }
}
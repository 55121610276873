.geo-location-container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .header{
        min-height: 4rem;
        max-height: 4rem;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        .back-icon{

        }
    }

    .modal-content{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .map-container{
            height: 95%;
            width: 90%;
            background-color: #E2E3E5;
            border-radius: 10px;
            overflow: hidden;
        }
    }

    .footer{
        display: flex;
        justify-content: center;
        height: 5rem;
        .btn-container{
            width: 9rem;
        }
    }
}
.champ-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
    max-height: 100vh;

    .content{
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}
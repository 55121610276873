.date-picker-container{

    .react-datepicker__portal{
        background-color: rgb(0 0 0 / 44%);
    }

    .date-picker-button-container{
        background: #FF6881;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 0.7em;
        letter-spacing: inherit;
        color: #FFFFFF;
        padding: 0.25rem 0.5rem;
        cursor: pointer;
    }

    .date-picker-container-container{
        display: flex;
        align-items: center;
        justify-content: center;

        .calendar-container{
            background-color: #ffffff;
            position: fixed;
            border-radius: 10px;
            overflow: hidden;
            width: 80%;

            .react-datepicker__header--custom{
                background-color: #ffffff;
                border-bottom: 0;

                .date-picker-action-container{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    height: 4rem;

                    .date-picker-btn-container{
                        display: flex;
                        button{
                            border: 0;
                            background-color: transparent;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            outline: none;
                            cursor: pointer;

                            svg{
                                height: 1.5rem;
                                width: 1.5rem;
                            }
                        }
                    }

                    .date-picker-label-container{
                        span{
                            font-weight: 700;
                            font-size: 1em;
                        }

                        span:first-child{
                            margin-right: 0.4rem;
                        }
                    }
                }

                .react-datepicker__day-names{
                    .react-datepicker__day-name{
                        font-weight: 700;
                        font-size: 0.9em;
                        color: #9D9D9D;
                    }
                }
            }

            .react-datepicker__week{
                .react-datepicker__day--outside-month{
                    font-weight: 700;
                    font-size: 0.9em;
                    color: #9D9D9D;
                }

                .react-datepicker__day{
                    font-weight: 700;
                    font-size: 0.9em;
                }

                .react-datepicker__day--selected{
                    background-color: #000000;
                    border-color: #000000;
                }
            }

            .react-datepicker__month-container{
                width: 100%;
                .react-datepicker__month{
                    .react-datepicker__month-text{
                        width: 4rem;
                        line-height: 4rem;
                        font-weight: 700;
                        font-size: 0.9em;
                    }
    
                    .react-datepicker__month--selected{
                        background-color: #000000;
                        border-color: #000000;
                    }
                }
            }

            
        }
    }
}


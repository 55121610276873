.add-visit-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    max-height: 100%;
    .visits-list{
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 0.7rem 1rem;
        overflow: auto;

        .total-lists{
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: auto;
            background-color: #f1eff2;
            border-radius: 10px;
            padding: 1rem 0.7rem;
            .home-card{
                display: flex;
                background-color: #fff;
                align-items: center;
                justify-content: center;
                padding: 0.7rem 1rem;
                border-radius: 10px;

                .card-text{
                    font-weight: 700;
                    font-size: 0.9em;
                    margin-left: 0.4rem;
                }
            }

            .distance-from-prev-location{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 0.5rem;

                .visit-distance{
                    min-width: 4.5rem;
                    height: 1.5rem;
                    background: #000000;
                    border-radius: 60px;
                    font-weight: 700;
                    font-size: 0.8em;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0.2rem 0;
                    position: relative;
                    padding: 0 0.5rem;
                }

                .distance-to-home{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 5rem;
                    height: 5rem;
                    position: relative;
                    background-color: #fff;
                    border-radius: 50px;
                    font-weight: 700;
                    font-size: 0.8em;

                    .back-to-home-distance{
                        margin-top: 0.2rem;
                    }
                }

                .distance-to-home::before{
                    content: "";
                    position: absolute;
                    top: 3px;
                    bottom: 3px;
                    left: 3px;
                    right: 3px;
                    border: 3px solid #64b28e;
                    border-radius: 50px;

                }

                .distance-to-home-text{
                    font-size: 0.8em;
                    font-weight: 600;
                    background-color: #64b28e;
                    padding: 0.3rem 1rem;
                    margin-top: 0.5rem;
                    border-radius: 50px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .visit-card{
                display: flex;
                justify-content: space-between;
                background: #FFFFFF;
                border-radius: 10px;
                align-items: center;
                padding: 0.5rem 0.6rem 0.5rem 1rem;
                margin-top: 0.5rem;

                .left{
                    display: flex;
                    flex-direction: column;

                    .visit-name{
                        font-weight: 700;
                        font-size: 0.9em;
                    }

                    .visit-status{
                        font-weight: 700;
                        letter-spacing: 0.02em;
                        
                        font-size: 0.6em;
                    }
                    .visit-status.Pending{
                        color: #169BD3;
                    }
                    .visit-status.Approved{
                        color: #64B28E;
                    }
                    .visit-status.Rejected{
                        color: #FF6881;
                    }
                }

                .right{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    .visit-time{
                        font-weight: 700;
                        font-size: 0.6em;
                        text-transform: uppercase;
                    }

                    .btn-container{
                        width: 5rem;
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }

    .day-actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 3rem;

        .info-window{
            font-size: 0.8em;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #bc001d;
            flex: 1;
            height: 3rem;
        }

        .btn-container{
            flex: 1;
        }

        .distance-container{
            flex: 1 1;
            background: #FFFFFF;
            box-shadow: 0px 1px 12px rgb(0 0 0 / 4%);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 51px;
            position: relative;
            height: 2.2rem;
            margin-right: 1rem;
            .small-text{
                font-weight: 700;
                font-size: 0.6em;
                margin-right: 0.5rem;
            }

            .medium-text{
                font-weight: 700;
                font-size: 0.9em;
                text-align: center;
            }
        }

        .distance-container.day-ended::after{
            content: "";
            position: absolute;
            top: 2px;
            bottom: 2px;
            left: 2px;
            right: 2px;
            border: 2px solid #FF4664;
            border-radius: 51px;
        }
    }

    .action-bar{
        display: flex;
        background-color: #fff;
        margin-bottom: 1rem;
        border-radius: 15px;
        overflow: hidden;
        align-items: center;
        font-weight: 700;
        font-size: 0.9em;
        min-height: 3rem;
        max-height: 3rem;
    }
}
.login-container{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    .bg{
        display: flex;
        padding: 0 0 0 1.5rem;
        align-items: flex-end;
        position: relative;
        justify-content: space-between;

        .app-name{
            display: flex;
            flex-direction: column;

            span:nth-child(1){
                font-weight: 400;
                letter-spacing: 0.02em;
                font-size: 1.55em;
            }

            span:nth-child(2){
                font-weight: 700;
                letter-spacing: 0.02em;
                font-size: 2.25em;
                line-height: 1.8rem;
            }
        }
    }

    .form-container{
        z-index: 2;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 0 1.5rem;
        

        .login-btn{
            margin-top: 15vh;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
.select-module-container{
    display: flex;
    height: 100%;
    flex: 1;
    flex-direction: column;
    padding: 1rem 1rem;
    justify-content: space-evenly;
}
.module-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 14px;
    padding: 3rem 1rem;

    .card-text{
        font-weight: 700;
        font-size: 1.1em;
        color: #000000;
        padding: 1rem 0;
    }

    .btn-container{
        width: 50%;
    }
}
.profile-details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 0rem;

    .profile-name{
        font-weight: 700;
        font-size: 1.1em;
        margin-top: 0.5rem;
    }

    .profile-email{
        font-weight: 400;
        font-size: 0.8em;
        color: #5A5A5A;
        margin-top: 0.2rem;
    }
}

.profile-info{
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    flex: 1;

    .item-list{
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        .label{
            font-weight: 700;
            font-size: 0.8em;
            color: #5A5A5A;
            margin-bottom: 0.2rem;
        }

        .value{
            font-weight: 700;
            font-size: 0.9em;
        }
    }
}

.logout-btn{
    padding: 1rem 0;
    max-width: 60%;
    align-self: center;
    min-width: 60%;
}
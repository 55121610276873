.button-container{
    background: linear-gradient(90deg, #FF6881 0%, #E62F4D 100%);
    box-shadow: 0px 15px 38px 8px rgb(230 47 77 / 15%);
    border-radius: 51px;
    cursor: pointer;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    button{
        all: unset;
        font-weight: 700;
        letter-spacing: 0.02em;
        color: #FFFFFF;
        font-size: 1.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        svg{
            margin-right: 0.5rem;
        }
    }

    button:disabled{
        background-color: #c3c3c3;
    }
}

.button-container.medium{
    height: 2.4rem;

    button{
        font-size: 0.9em;
    }
}

.button-container.small{
    height: 2.2rem;

    button{
        font-size: 0.8em;
    }
}

.button-container.extrasmall{
    height: 1.5rem;

    button{
        font-size: 0.7em;

        svg{
            margin-right: 0.2rem;
        }
    }
}

.button-container.blue{
    background: #169BD3;
    box-shadow: 0px 15px 38px 8px rgba(108, 164, 204, 0.15);
}

.button-container.green{
    background: #64B28E;
    box-shadow: 0px 15px 38px 8px rgba(108, 204, 123, 0.15);
}

.button-container.grey{
    background: linear-gradient(90deg, #b2b1b1 0%, #868282 100%);
    box-shadow: 0px 15px 38px 8px rgb(193 182 184 / 15%)
}
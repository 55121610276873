.geo-location-container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .header{
        min-height: 4rem;
        max-height: 4rem;
        background: #FFFFFF;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        .back-icon{

        }
    }

    .modal-content{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .map-container{
            height: 95%;
            width: 90%;
            background-color: #E2E3E5;
            border-radius: 10px;
            overflow: hidden;
        }
    }

    .visit-details{
        display: flex;
        flex-direction: column;
        flex: 0.5;
        padding: 1rem 1rem;

        .form-control{
            border: 1px solid #f1f3f4;
            height: 2rem;
            border-radius: 5px;
            // margin-bottom: 1rem;
            padding: 0.5rem 0.5rem;
            overflow: hidden;

            input, textarea{
                min-width: 100%;
                padding: 0px;
                margin: 0px;
                max-height: 100%;
                min-height: 100%;
                max-width: 100%;
                border: 0;
                outline: 0;
            }
        }

        .form-control.textarea {
            flex: 1;
        }
    }

    .footer{
        display: flex;
        justify-content: center;
        height: 5rem;
        flex-direction: column;
        align-items: center;
        .btn-container{
            width: 9rem;
        }

        .notice{
            font-size: 11px;
        }
    }
}
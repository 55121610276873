.user-reportees-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    max-height: 100%;

    .header{
        font-weight: 700;
        font-size: 0.9em;
    }

    .users-list{
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        min-height: 100%;
        max-height: 100%;
        overflow: auto;
        flex: 1;
        .list{
            display: flex;
            background: #FFFFFF;
            border-radius: 14px;
            padding: 1rem 1rem;
            margin-bottom: 0.5rem;
            align-items: center;

            .avatar{
                width: 2rem;

                img{
                    width: 100%;
                }
            }

            .user-details{
                margin-left: 1rem;
                display: flex;
                flex-direction: column;
                .name{
                    font-weight: 700;
                    font-size: 0.9em;
                }

                .email{
                    font-weight: 400;
                    color: #5A5A5A;
                    font-size: 0.8em;
                    margin-top: 0.2rem;
                }
            }
        }

        .user-history-content{
            display: flex;
            flex-direction: column;
            flex: 1;
            min-height: 100%;
            max-height: 100%;

            .action-bar{
                display: flex;
                background-color: #fff;
                margin-bottom: 1rem;
                border-radius: 15px;
                overflow: hidden;
                align-items: center;
                font-weight: 700;
                font-size: 0.9em;
                min-height: 3rem;
                max-height: 3rem;
            }

        }
    }
}
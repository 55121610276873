.list-reportees-container{
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
    max-height: 100%;
    padding: 1rem 1rem;

    .header{
        font-weight: 700;
        font-size: 0.9em;
    }

    .users-list{
        display: flex;
        flex-direction: column;
        margin-top: 1.5rem;
        min-height: 100%;
        max-height: 100%;
        overflow: auto;
        flex: 1;
        .list{
            display: flex;
            background: #FFFFFF;
            border-radius: 14px;
            padding: 1rem 1rem;
            margin-bottom: 0.5rem;
            align-items: center;
            position: relative;
            overflow: hidden;
            min-height: 3rem;
            max-height: 3rem;

            .avatar{
                width: 2rem;

                img{
                    width: 100%;
                }
            }

            .user-details{
                margin-left: 1rem;
                display: flex;
                flex-direction: column;
                flex: 1;
                .name{
                    font-weight: 700;
                    font-size: 0.9em;
                }

                .email{
                    font-weight: 400;
                    color: #5A5A5A;
                    font-size: 0.8em;
                    margin-top: 0.2rem;
                }
            }

            .user-trip-details{
                display: flex;
                flex-direction: column;
                align-items: center;

                .trip-approval-status{
                    font-weight: 400;
                    font-size: 0.8em;
                    margin-bottom: 0.5rem;
                    text-transform: capitalize;
                }

                .trip-approval-status.approved{
                    color: #64B28E;
                }

                .trip-approval-status.pending{
                    color: #169BD3;
                }

                .trip-approval-status.rejected{
                    color: #FF536F;
                }

                .trip-total-distance{
                    background: #169BD3;
                    border-radius: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0.3rem 1rem;
                    font-weight: 700;
                    font-size: 0.8em;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                }
            }
        }

        .list.pending::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0.5rem;
            background-color: #FF6881;
        }

        .list.verified::before{
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0.5rem;
            background-color: #64B28E;
        }

        .user-history-content{
            display: flex;
            flex-direction: column;
            flex: 1;
            min-height: 100%;
            max-height: 100%;

            .action-bar{
                display: flex;
                background-color: #fff;
                margin-bottom: 1rem;
                border-radius: 15px;
                overflow: hidden;
                align-items: center;
                font-weight: 700;
                font-size: 0.9em;
                padding: 1rem 0;
            }

        }
    }
}
.footer-container{
    background-color: #FFFFFF !important;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;

    a{
        .action-icon{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    a.active{
        .action-icon{
            svg{
                rect.active-color{
                    fill: #FF6881;
                }

                path.active-color{
                    stroke: #FF6881;
                }
            }
        }
    }
   
}
.day-ended-modal-container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .day-modal-box{
        background: #FFFFFF;
        border-radius: 10px;
        height: 10rem;
        width: 80%;
        display: flex;
        flex-direction: column;

        .day-modal-box-body{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 1rem;
            font-weight: 500;
            font-size: 1em;
            text-align: center;
        }

        .day-modal-box-footer{
            display: flex;

            .btn{
                flex: 1;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 0.9em;
                cursor: pointer;
                border-top: 1px solid rgba(0, 0, 0, 0.07);
            }

            .btn:first-child{
                border-right: 1px solid rgba(0, 0, 0, 0.07);
            }
        }
    }
}
.header-container{
    min-height: 4rem;
    max-height: 4rem;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;

    .logo{
        display: flex;
        align-items: center;
    }

    .actions{
        display: flex;
        align-items: center;
        .date-select{
            margin-right: 0.5rem;
        }

        .notifications{
            cursor: pointer;
        }
    }
}